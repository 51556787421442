<template>
  <div>
    <svg
      class="modal-header__close-svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L7 7M7 7L1 13M7 7L13 13M7 7L1 1"
        stroke="#1C2021"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CloseIcon",
}
</script>